<template>
<!--  -->
    <div class="tab-content" >
		<div class='card'>
			<div class='chart-header-row'>
				<span class="statement-title p-text-bold">{{selectedEquity.symbol}} Financials</span>
				<div class='buttons-container'>
					<SelectButton class='period-toggle' v-model="selectedPeriodType" :options="periodTypes" optionLabel="name" :disabled="loadingData"/>
					<MoreOptionsMenu class='more-options' @click="toggleMenu"/>
					<Menu ref="menu" :model="chartMenuItems" :popup="true" />
					<!-- <SaveViewModal ref="saveViewModal"/>
					<AddToNoteModal ref='addToNoteModal'/> -->
				</div>
			</div>
			<FinancialsChart  :selectedSymbol="selectedSymbol" key="financialsChart"  ref="finChart" />
		</div>
		
		<PillMenuBar :navigationItems="menuItems" headerType="sub" />
		<div class="card">
			<div class='metrics-header-row' :style="(selectedSymbol.endsWith(':AQL')? 'margin-bottom:0;' : '')">
				<span class="statement-title p-text-bold">{{sourceName}}</span>
				
				<div class="buttons-container">
						<span class="p-input-icon-right" style="margin-right:10px;" v-if="metricsSelected" @click="showPremPromptIfRequired()" v-tooltip.bottom="(!isPremUser ? 'Purchase a Premium Subscription or Investor Pass to access more metrics.' : '')">
						<AutoComplete ref='metricSearchBar' id='fiscalMetricSearchBar' placeholder="Search More Metrics" forceSelection v-model="selectedMetric" :disabled="!isPremUser"
							:suggestions="filteredMetrics" @complete="searchMetrics($event)" field="name" @click="clearInput($event)"
							@item-select="onMetricSelected($event)"
							class="fiscal-metric-search" >
							<template #item="slotProps">
				
									<span v-tooltip.left="getMetricDesc(slotProps.item)">{{slotProps.item.name}}</span>
									
								</template>
						</AutoComplete>

						<i class="pi pi-search" />

					</span>
					
					<Button class="blue-button reset-button" icon="pi pi-eraser" label="Reset" :disabled="loadingData || selectedToGraph.length === 0" @click="resetSelectedRows" v-tooltip.bottom="'Clear All Selections'"/>
					<SelectButton class='period-toggle' v-model="selectedPeriodType" :options="periodTypes" optionLabel="name" :disabled="loadingData"/>
				</div>
			</div>
			<div v-if="selectedSymbol.endsWith(':AQL')" style="padding-left: 16px;margin-bottom: 16px;"><small >(Note: All financial data and metrics are based on the US company.)</small></div>
			<DataTable :value="rows" :rowClass="rowClassViaTemplateType" :loading="loadingData"  class="p-datatable-sm data-point-table" id='finDataTable'
				:scrollable="true" scrollHeight="calc(100vh - 310px)" scrollDirection="horizontal" v-model:selection="selectedToGraph" :selectionMode="currentSelectionMode" dataKey="dataItemId" 
				:metaKeySelection="false"  ref="table"
				@row-select="onRowSelected" @row-unselect="onRowUnSelected" >
				<Column :frozen="true" :style="(selectedSource == METRICS ? 'max-width:80px;' : 'max-width:50px;')">
					<template #body="slotProps">
						<Button icon="pi pi-info" @click="toggleShowDescriptionModal(slotProps.data)" class="item-info-button"  v-tooltip.top="'View Description'" />
						<Button v-if="isUserMetric(slotProps.data)" icon="pi pi-trash" @click="removeMeasure(slotProps.data)" class="delete-metric-button"  v-tooltip.top="'Remove metric'" />
					</template>
				</Column>
				<Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field" :style="col.style" :frozen="col.frozen">
					<template #body="slotProps">
						<div :class="valueClass(slotProps.data[col.field])"  >
							
							<span>
									{{slotProps.data[col.field]}}
							</span>
						</div>
					</template>
				</Column>
				
				<template #empty>
					<div style="text-align: center;">Select a financial statement or metric to see available data sets.</div>
				</template>
				
			</DataTable>
			

		</div>
		<AttributionFooter authors='XIgnite' />
    </div>
	<UpgradeToPremiumModal ref="upgradeToPremiumModal" :type="PREMIUM_SUBSCRIPTION_TYPES['PREMIUM_ANALYSIS']" :bodyText="upgradeText" @cancel-upgrade="cancelUpgrade" />
	<Dialog id="itemDescriptionDialog" v-model:visible="showDescription"  :header="currentItemName"  :position="'top'" style="width:40rem;" >
    
    
        <div class="desc-content" >
			{{currentItemDescription}}
        </div>
        <template #footer>
            </template>
    
    </Dialog>
</template>

<script>
import AttributionFooter from '../footer/AttributionFooter.vue';
import PillMenuBar from '../menu/PillMenuBar';
import FinancialsChart from '../charts/highcharts/FinancialsChart.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import DataService from '../../service/DataService';
import SelectButton from 'primevue/selectbutton';
import MoreOptionsMenu from '../menu/MoreOptionsMenu'
import {PREMIUM_SUBSCRIPTION_TYPES} from '../../common/constants';

// import AddToNoteModal from '../modal/AddToNoteModal';
// import SaveViewModal from '../modal/SaveViewModal';

import { mapState } from 'vuex';
import format from 'date-fns/format';
import numeral from 'numeral';

import { STATEMENT_TEMPLATE_IDS } from './common.js';
import SecurityFormatter from '../../common/SecurityFormatter';

import UserUtils from '../../utilities/UserUtils';
import AutoComplete from 'primevue/autocomplete';
import UpgradeToPremiumModal from '../modal/UpgradeToPremiumModal.vue';


import Dialog from 'primevue/dialog';


/*
	How this works:
	Click on one of the statement or metric buttons makes a call to DataService.getFinancials/getMetrics
	which returns a Financials/Metrics object.
	This object is used to create both the column header definitions e.g. FY20, FY19 or Q1/20 Q2/20
	and is also used to create the rows of data.
	After the initial fetch the data is cached localy in the store's selectedEquity's financials/metrics member which is map (added dynamically in activated method).
	Subsequenty clicks to the statement/metric buttons check if the data is already stored locally before attemting to fetch the data again.
	Changing the period type (annual/quarterly) also triggers this same fetch logic.
	Currently upto 5 data sets (rows) across all statement types/metrics can be selected.  

*/
const ANNUAL = 1;
const QUARTERLY = 2;
const BALANCE_SHEET = 2;
const INCOME_STMT = 1;
const CASHFLOW_STMT = 3;
const METRICS = 4;

const TEMPLATE_ROW_STYLING_MAP = {
	[STATEMENT_TEMPLATE_IDS['INDUSTRY']]: {
		[INCOME_STMT]: ['Gross Income', 'EBIT(Operating Income)', 'Pre Tax Income', 'Consolidated Net Income', 'Net Income Available To Common Basic',
	'EPS Diluted Before Unusual Expense', 'EBITDA'],
		[BALANCE_SHEET]: ['Total Current Assets', 'Total Assets', 'Total Current Liabilities', 'Total Liabilities', 'Total Equity'],
		[CASHFLOW_STMT]: ['Net Change In Cash', 'Net Operating Cash Flow', 'Net Investing Cash Flow', 'Net Financing Cash Flow'],
		[METRICS]: []
	},

	[STATEMENT_TEMPLATE_IDS['BANK']]: {
		[INCOME_STMT]: ['Net Interest Income', 'Net Interest Income After Loan Loss Provision', 'Operating Income', 'Pre Tax Income', 'Consolidated Net Income', 'Net Income Available To Common Basic',
	'EPS Diluted Before Unusual Expense'],
		[BALANCE_SHEET]: ['Net Loans', 'Total Assets', 'Total Deposits', 'Total Liabilities', 'Total Equity'],
		[CASHFLOW_STMT]: ['Net Change In Cash', 'Net Operating Cash Flow', 'Net Investing Cash Flow', 'Net Financing Cash Flow'],
		[METRICS]: []
	},

	[STATEMENT_TEMPLATE_IDS['INSURANCE']]: {
		[INCOME_STMT]: ['Operating Income Before Interest Expense', 'Net Interest Income', 'Net Interest Income After Loan Loss Provision', 'Operating Income', 'Pre Tax Income', 'Consolidated Net Income', 'Net Income Available To Common Basic',
	'EPS Diluted Before Unusual Expense'],
		[BALANCE_SHEET]: ['Total Liabilities', 'Total Assets', 'Total Equity'],
		[CASHFLOW_STMT]: ['Net Change In Cash', 'Net Operating Cash Flow', 'Net Investing Cash Flow', 'Net Financing Cash Flow'],
		[METRICS]: []
	},

	[STATEMENT_TEMPLATE_IDS['OTHER_FINANCIAL']]: {
		[INCOME_STMT]: ['Operating Income', 'Pre Tax Income', 'Consolidated Net Income', 'Net Income Available To Common Basic', 'EPS Diluted Before Unusual Expense'],
		[BALANCE_SHEET]: ['Total Liabilities', 'Total Equity', 'Total Assets'],
		[CASHFLOW_STMT]: ['Net Change In Cash', 'Net Operating Cash Flow', 'Net Investing Cash Flow', 'Net Financing Cash Flow'],
		[METRICS]: []
	},

	[STATEMENT_TEMPLATE_IDS['GENERAL']]: {
		[INCOME_STMT]: ['Gross Income', 'EBIT(Operating Income)', 'Pre Tax Income', 'Consolidated Net Income', 'Net Income Available To Common Basic',
	'EPS Diluted Before Unusual Expense', 'EBITDA'],
		[BALANCE_SHEET]: ['Total Current Assets', 'Total Assets', 'Total Current Liabilities', 'Total Liabilities', 'Total Equity'],
		[CASHFLOW_STMT]: ['Net Change In Cash', 'Net Operating Cash Flow', 'Net Investing Cash Flow', 'Net Financing Cash Flow'],
		[METRICS]: []
	}
}

const DEFAULT_SELECTED_ROWS = {
	[STATEMENT_TEMPLATE_IDS['INDUSTRY']]: ['Sales Or Revenue', 'Gross Income'],
	[STATEMENT_TEMPLATE_IDS['BANK']]: ['Net Interest Income', 'Consolidated Net Income'],
	[STATEMENT_TEMPLATE_IDS['INSURANCE']]: ['Sales Or Revenue', 'Losses Claims Reserves'],
	[STATEMENT_TEMPLATE_IDS['OTHER_FINANCIAL']]: ['Sales Or Revenue', 'Gross Income'],
	[STATEMENT_TEMPLATE_IDS['GENERAL']]: ['Sales Or Revenue', 'Gross Income'] 
}

const DEFAULT_METRICS = [3,4,5,6,7,13,15,17,25,27,33,79,198,341,342,355,358,363,364,400];

// const ROW_NAME_FORMATTER = {
// 	//'Fixed Charges Coverage': '0.0000',
// 	//'Pre Tax Interest Coverage Ratio': '0.0000',
// 	'Current Ratio': '0.0000',
// 	'Cash Ratio': '0.0000',
// 	'Quick Ratio': '0.0000'
// }
// const VALUE_TYPE_FORMATTER = {
// 	'Millions': '0.00a',
// 	'Ratio': '0.00',
// 	'Percent': '0.00%',
// 	'$ & cents': '0.00a',
// 	'Actual': '0.0000'
// }

const VALUE_TYPE_FORMATTER = {
	'Millions': '0.0a',
	'Ratio': '0.00',
	'Percent': '0.00%',
	'$ & cents': '0.00a',
	'Actual': '0.00',
	'Decimal Percentage' : '0.00%',
}

export default {
    name: "Financials",
	components : {
        PillMenuBar, DataTable, Column, FinancialsChart, SelectButton, MoreOptionsMenu, //AddToNoteModal, SaveViewModal
		AttributionFooter,AutoComplete,UpgradeToPremiumModal, Dialog
    },
    data() {
		return {
			PREMIUM_SUBSCRIPTION_TYPES,
			METRICS,
			justMounted: false,
			active: false,
			menuItems: [
					{label: "Income Statement", action: this.onIncomeStatementClicked, selected: false},
					{label: "Balance Sheet", action: this.onBalanceSheetClicked, selected: false},
					{label: "Cash Flow Statement", action: this.onCashFlowClicked, selected: false},
					{label: "Metrics", action: this.onMetricsClicked, selected: false},
					{label: "Saved Views", action: this.onSavedViewsClicked, selected: false}
			],
			columns: null,
			rows:[],
			currentSymbol: "",
			incomeStmtBtnClickedFirstTime: false,
			selectedToGraph: [],
			loadingData: false,
			selectionFull: false,
			currentSelectionMode: "multiple",
			periodTypes: [{name:'Annual', value: ANNUAL}, {name:'Quarterly', value: QUARTERLY}],
			selectedPeriodType: null,
			selectedSource: null,
			sourceName: "",
			scrollDirection: null,
			scrollable: false,
			categories: [],
			chartMenuItems: [
				{
                    label: 'Take Snapshot',
					
                    icon: 'pi pi-camera',
                    command: () => {
						this.$refs.finChart.openSnapshotDialog();
                    }
                },
                
			],
			
			filteredMetrics: [],
			selectedMetric: null,
			upgradeText: 'To get full access to hundreds of metrics and the other premium features below, upgrade to premium.',
			
			expandedRows: [],
			currentItemDescription: '',
			currentItemName: '',
			showDescription: false,
		}
	},

	mounted() {
		
		this.justMounted = true;
		console.debug(this.$options.name + " mounted");
	},
	activated() {
		this.active = true;
		console.debug(this.$options.name + " activated");

		if( !this.selectedEquity.financials || !this.selectedEquity.financials.has ){
			this.selectedEquity.financials = new Map();
		}
		if( !this.selectedEquity.metrics ||  !this.selectedEquity.metrics.has ){
			this.selectedEquity.metrics = new Map();
		}

		if( this.currentSymbol != this.selectedSymbol ) {
			this.currentSymbol = this.selectedSymbol;
			this.resetTable();
			this.$nextTick().then(() => {
				if (this.active) {
					this.menuItems[0].selected = true;
					//this.onMetricsClicked();
					this.$refs.finChart.showLoading('Loading data...');
					this.onIncomeStatementClicked();

				}
			});
			
		}
		
		
		
		this.justMounted = false;
	},
	watch:{
		selectedPeriodType() {

			if (this.active) {
				//console.debug("period type changed" );
				this.updatePeriods();
				this.expandedRows = [];
				this.$nextTick(() => {
					this.scrollRight();
				});
			}

		}, 

		scrollable(val) {
			if( val ) {
				const dataTableWrapper = document.querySelector('#finDataTable .p-datatable-wrapper')
				if(dataTableWrapper){
					dataTableWrapper.scrollLeft= 2000;
				}
			}
		}
	},

    
    computed: {
		...mapState(['selectedEquity']),
		...mapState(['equityOverview']),
        selectedSymbol() {
					return SecurityFormatter.getSymbolViaCdnExchanges(this.selectedEquity);
        },

		isPremUser() {
			return UserUtils.isPremiumSubscriber();
		},

		metricsSelected() {
			
			return this.selectedSource == METRICS;
		},
		
		

    },
    
    
	deactivated() {
		this.justMounted = false;
		this.active = false;
        console.debug(this.$options.name + " dectivated");
	},
    methods: {

		getMetricDesc(m){
			if( m ){
				if(m.description){
					if(m.description.length > 500){
						return m.description.substring(0,500)+"...";
					}
					else {
						return m.description;
					}
				}
				else {
					return ""
				}
			}
			else {
				return "";
			}
			
		},

		toggleShowDescriptionModal( item ) {
			if( this.showDescription && item.name == this.currentItemName ){
				this.showDescription = false;
			}
			else {
				this.currentItemName = item.name;
				if( item.description ) {
					this.currentItemDescription = item.description;
					this.showDescription = true;
				}
				else {
					if( this.selectedSource == METRICS){
						
						DataService.getMeasureDescription(item.dataItemId).then(resp=> {
							if( resp.data.status == "success")
								//desc = resp.data.message;
							
							item['description'] = resp.data.message;
							this.currentItemDescription = item.description;
							this.showDescription = true;
						})
						
					}
					else {
						DataService.getLineItemDescription(item.dataItemId).then(resp=> {
							if( resp.data.status == "success")
								
								item['description'] = resp.data.message;
								this.currentItemDescription = item.description;
								this.showDescription = true;
						})
					
					}
				}
			}

		},
		
		
		cancelUpgrade() {
		},

		showPremPromptIfRequired() {
			if( !this.isPremUser ){
				this.$refs.upgradeToPremiumModal.open();
			}
		},

		isUserMetric(m){
			return m.isUserMetric;
		},

		deselectUserMeasureIfRequired(m){
			//console.log("selected to graph ", this.selectedToGraph, " and m ", m);
			this.selectedToGraph = this.selectedToGraph.filter( item => { return item.dataItemId != m.dataItemId});
			this.onRowUnSelected();
		},

		removeMeasure(m){
			this.deselectUserMeasureIfRequired(m);


			DataService.removeUserMetric(m.dataItemId).then(resp => {
				if( resp.data.status == 'success'){
					this.selectedEquity.metrics.delete(this.selectedSource+"-"+this.selectedPeriodType.value);
					this.fetchData();
				}
				else {
					console.log("error saving metrics", resp.data);
				}
			}).catch(err => {
				console.log("error saving metrics", err);
			})

		},
		searchMetrics(event) {
			
            DataService.searchMetrics(this.selectedEquity.securityId, this.selectedPeriodType.value, event.query.toLowerCase(), 300, 0).then(resp => {
				if( resp.data.status == "success"){
					this.filteredMetrics = resp.data.results.data;
				}
				else {
					console.log("error searching metrics", resp.data);
				}
			}).catch(err => {
				console.log("error searching metrics", err);
			})
           
        },

		onMetricSelected(event){
			DataService.saveUserMetric(event.value.measureId).then(resp => {
				if( resp.data.status == 'success'){
					this.selectedEquity.metrics.delete(this.selectedSource+"-"+this.selectedPeriodType.value);
					this.fetchData();
					this.selectedMetric = {name:""};
				}
				else {
					console.log("error saving metrics", resp.data);
				}
			}).catch(err => {
				console.log("error saving metrics", err);
			})
			if( document.getElementById('fiscalMetricSearchBar') ) {
				this.$refs.metricSearchBar.$el.blur();
				document.getElementById('fiscalMetricSearchBar').blur();
			}
		},

		clearInput(e) {
			e['query'] = '';
            this.$refs.metricSearchBar.$emit('complete', e)
			this.$refs.metricSearchBar.show();
			this.selectedMetric = {name:""};
        },


		scrollRight() {
			document.querySelector('#finDataTable .p-datatable-wrapper').scrollLeft = 2000;
		},

		/* This is to style a column field containing the values and depending on the values */
		valueClass(val) {
			return [
				{
					'negative': numeral(val).value() < 0
				}
			]
		},
		rowClassViaTemplateType(data) {
			let templateId = this.equityOverview.company.statementTemplateId;

			let rowClass = null;
			
			if (TEMPLATE_ROW_STYLING_MAP[templateId][this.selectedSource]) {
				rowClass = TEMPLATE_ROW_STYLING_MAP[templateId][this.selectedSource].includes(data.name) ? 'template-row' : null;
			}

			return rowClass;
		},

		toggleMenu(event) {
            this.$refs.menu.toggle(event);
        },
		resetTable() {
			this.menuItems.forEach((item)=>{
				item.selected = false;
			});
			this.selectedSource = null;
			this.selectedPeriodType = this.periodTypes[0];
			this.rows = [];
			this.columns = [];
			this.selectedToGraph = [];
			this.categories = [];
			this.incomeStmtBtnClickedFirstTime = false;
		},
		onRowSelected() {
			
			if( this.selectedToGraph.length > 5 ) {
				this.selectedToGraph.pop();
				this.$toast.add({severity:'warn', summary: 'Max Data Points', detail:'You can only chart up to 5 data sets. ', life: 3000, group: 'tc'});
			}
			else {
				this.$refs.finChart.updateSeries(this.selectedToGraph);
			}
			

		},

		onRowUnSelected() {
			this.$refs.finChart.updateSeries(this.selectedToGraph);
		},

		/** 
		 * Creates the columns that show on the data table
		 * financials can actually be a Financials object or a Metrics object as they both have the same required fields
		 */
		createColumns(financials) {
			let cols = [];
			let nameStyle = "min-width: 250px;" // (financials.periodTypeId == QUARTERLY)? {width: "380px"}: null;
			cols.push({field: "name", header: "Name", style:nameStyle, frozen: true } );

			financials.statementIntervals.forEach(line => {
				//console.log("create date from " + line.statementDate);
				let stmtDate = new Date(line.yearEnd);
				let field = (financials.periodTypeId == ANNUAL) ? 'val_fy_'+format(stmtDate, "yy") : "val_q_"+line.stmtQuarter+"_"+format(stmtDate, "yy");
				let header = (financials.periodTypeId == ANNUAL) ? 'FY'+format(stmtDate, "yy") : "Q"+line.stmtQuarter+"/"+format(stmtDate, "yy");

				let col = { field: field, header: header, style: "min-width: 98px;", frozen: false};
				
				cols.push(col);
			});

			return cols;
		},

		/**
		 * Creates the categories that appear on the x-axis on the chart e.g. Q1/20 Q2/20 or FY20 FY21 etc.
		 * financials can be either a Financials object or a Metrics object as the both have the same required fields
		 */
		createXAxisCategories(financials) {
			this.categories = []
			financials.statementIntervals.forEach(line => {
				let stmtDate = new Date(line.yearEnd);
				let cat = (financials.periodTypeId == ANNUAL) ? 'FY'+format(stmtDate, "yy") : "Q"+line.stmtQuarter+"/"+format(stmtDate, "yy");
				this.categories.push(cat);
			});
			this.$refs.finChart.updateCategories(this.categories);
		},

		/** 
		 * Creates the financial rows of the table for a particular statement type and period.
		 * This function transposes the statementValues rows into columns in a single row based on the line item.
		 * Each row created has a column value for each quarter/year end as well as a series array that makes it easy to chart.
		 * 
		 */
		createRowsFromStatements(stmtTypeId, periodTypeId, statementValues) {
			let lineMap = new Map();
			/* begin non intuitive section
			* we store the what will be the index into the series array for each column in the seriesMap variable
			* this is so that if we are missing data we don't throw the whole chart off
			*/
			let seriesMap = new Map();
			let index = 0;
			this.columns.forEach( col => {
				if( col.field != 'name') {
					seriesMap.set(col.field, index);
					++index;
				}
			});

			statementValues.forEach(line => {
				if( !lineMap.has(line.dataItemId) ) {
					//console.log(JSON.stringify(line));
					let newRow = {name: line.name, dataItemId: line.dataItemId, stmtType: stmtTypeId, valueType: line.valueType, series:[]};
					
					this.columns.forEach( col => {
						if( col.field != 'name') {
							newRow[col.field] = 0;
							newRow.series.push(0);// initialize series to zero so we're not missing data points otherwise chart will be off
						}
					});
					lineMap.set(line.dataItemId, newRow);


				}
				let row = lineMap.get(line.dataItemId);
				//let stmtDate = new Date(line.statementDate);
				if(periodTypeId == ANNUAL ){
					let field = 'val_fy_'+ (""+line.stmtYear).substring(2);//format(stmtDate, "yy");
					row[field] = this.formatValue(line);
					
					row.series[seriesMap.get(field)] = numeral(line.value)._value; // get the index into the series base on the field name
				}
				else {
					let field = "val_q_"+line.stmtQuarter+"_"+(""+line.stmtYear).substring(2);//format(stmtDate, "yy");
					row[field] = this.formatValue(line);
					row.series[seriesMap.get(field)] = numeral(line.value)._value; // get the index into the series base on the field name
				}
			});

			return Array.from(lineMap.values()).sort((a,b) => {
				if( a.name < b.name){
					return -1;
				}else {
					return 1;
				}
			});
		},

		formatValue(line) {
			let rawLine = line;
			//let formattedValue = null;


			if( rawLine && rawLine.value) {
				if( rawLine.valueType == 'Decimal Percentage'){
					return numeral(rawLine.value).divide(100.0000).format(VALUE_TYPE_FORMATTER[rawLine.valueType]);
				}
				else if (rawLine.valueType == 'Ratio') {
					return numeral(rawLine.value).format(VALUE_TYPE_FORMATTER[rawLine.valueType]) + 'x';
				}
				else {
					return numeral(rawLine.value).format(VALUE_TYPE_FORMATTER[rawLine.valueType]);
				}
			}
			else {
				return "-";
			}


			// if (ROW_NAME_FORMATTER[rawLine.name]) {
			// 	formattedValue = numeral(rawLine.value).format(VALUE_TYPE_FORMATTER[rawLine.name]) + 'x';
			// } else {
			// 	formattedValue = numeral(rawLine.value).format(VALUE_TYPE_FORMATTER[rawLine.valueType])
			// }

			// return formattedValue
		},

		/**
		 * Creates the metric rows for a particular period type.
		 * This function transposes the metricValue rows into columns in a single row based on the metric.
		 * Each row created has a column value for each quarter/year end as well as a series array that makes it easy to chart.
		 */
		createRowsFromMetrics( periodTypeId, metricValues) {
			let lineMap = new Map();
			/* begin non intuitive section
			* we store the what will be the index into the series array for each column in the seriesMap variable
			* this is so that if we are missing data we don't throw the whole chart off
			*/
			let seriesMap = new Map();
			let index = 0;
			this.columns.forEach( col => {
				if( col.field != 'name') {
					seriesMap.set(col.field, index);
					++index;
				}
			});
			
			//console.log("raw metric values are ", metricValues);

			metricValues.forEach(line => {
				if( !lineMap.has(line.measureId) ) {
					let newRow = {name: line.name, dataItemId: line.measureId, stmtType: METRICS, valueType: line.valueType, series:[], isUserMetric: false, description: line.description};
					if( !DEFAULT_METRICS.includes(line.measureId)){
						newRow.isUserMetric = true;
					}
					this.columns.forEach( col => {
						if( col.field != 'name') {
							newRow[col.field] = 0;
							newRow.series.push(0);// initialize series to zero so we're not missing data points otherwise chart will be off
						}
					});
					lineMap.set(line.measureId, newRow);
				}
				let row = lineMap.get(line.measureId);
				//let stmtDate = new Date(line.statementDate);
				if(periodTypeId == ANNUAL ){
					let field = 'val_fy_'+(""+line.stmtYear).substring(2);//format(stmtDate, "yy");
					row[field] = this.formatValue(line);
					row.series[seriesMap.get(field)] = numeral(line.value)._value // get the index into the series base on the field name
					
				}
				else {
					let field = "val_q_"+line.stmtQuarter+"_"+(""+line.stmtYear).substring(2);//format(stmtDate, "yy")
					row[field] = this.formatValue(line);
					
					row.series[seriesMap.get(field)] = numeral(line.value)._value // get the index into the series base on the field name
				}
			});
			//console.log("line map is ", lineMap.values())
			return Array.from(lineMap.values()).sort((a,b) => {
				if( a.name < b.name){
					return -1;
				}else {
					return 1;
				}
			});
		},

		
		/**
		 * When the period is changed this function loads the previously cached financials/metrics for the specified period type
		 * into the table and updates the chart.
		 */
		updatePeriods() {
		
			if( this.selectedSource  ) {
				let key = this.selectedSource+"-"+this.selectedPeriodType.value;
				if( this.selectedSource == METRICS) {
					let metrics = this.selectedEquity.metrics.get(key)
					this.createXAxisCategories(metrics);
					this.columns = this.createColumns(metrics);
					this.rows = this.createRowsFromMetrics(metrics.periodTypeId, metrics.metricValues);
					
					
					this.updateSelectedLinesToGraph();
				}
				else {
					let financials = this.selectedEquity.financials.get(key)
					this.createXAxisCategories(financials);
					this.columns = this.createColumns(financials);
					this.rows = this.createRowsFromStatements(financials.stmtTypeId, financials.periodTypeId, financials.statementValues);
					
					
					this.updateSelectedLinesToGraph();
				}

			}
			
		},

		/**
		 * This function takes care of loading the financials/metrics either from the local cache or via an api call.
		 * 
		 */
		fetchData(){
			try {
				this.rows = [];
				this.loadingData=true;
				let key = this.selectedSource+"-"+this.selectedPeriodType.value;
				//this.selectedEquity.metrics = new Map();
				if( this.selectedSource == METRICS ){
					if( !this.selectedEquity.metrics.has(key) ) {
						DataService.getMetrics(this.selectedEquity.securityId)
							.then(resp =>{
								//console.log(JSON.stringify(resp.data));
								this.selectedEquity.metrics.set(this.selectedSource+"-"+ANNUAL, resp.data[0]);//store annual  data
								this.selectedEquity.metrics.set(this.selectedSource+"-"+QUARTERLY, resp.data[1]);// store quarterly  data
								//this.selectedEquity.metrics.set(key, resp.data);
								//console.log("quaterly metrics:", this.selectedEquity.metrics.get(this.selectedSource+"-"+QUARTERLY));
								let metrics = this.selectedEquity.metrics.get(key)
								this.createXAxisCategories(metrics);
								//if( this.columns.length <= 0 ) {
									this.columns = this.createColumns(metrics);
								//}
								this.rows = this.createRowsFromMetrics( metrics.periodTypeId, metrics.metricValues);
								
								
								this.scrollable = true;
								this.loadingData=false;
								this.$nextTick(() => {
									this.scrollRight();
								});
							}).catch(err => {
								console.log("error getting financial data", err);
								this.loadingData = false;
								this.$toast.add({severity:'error', summary: 'Unable to get financial data', detail:'There was an unexpected error getting the financial data.  Please contact support if the problem persists. ', life: 7000, group: 'tc'});
							
							});
					}
					else {
						let metrics = this.selectedEquity.metrics.get(key)
						this.createXAxisCategories(metrics);
						this.columns = this.createColumns(metrics);
						this.rows = this.createRowsFromMetrics(metrics.periodTypeId, metrics.metricValues);
						
						this.scrollable = true;
						this.loadingData=false;
						this.$nextTick(() => {
									this.scrollRight();
								});
					}
				}
				else {
					if( !this.selectedEquity.financials.has(key) ) {
						// change this to fetch both time periods
						DataService.getFinancials(this.selectedEquity.securityId, this.selectedSource)
							.then(resp =>{
								//console.log(JSON.stringify(resp.data));
								this.selectedEquity.financials.set(this.selectedSource+"-"+ANNUAL, resp.data[0]);//store annual statement data
								this.selectedEquity.financials.set(this.selectedSource+"-"+QUARTERLY, resp.data[1]);// store quarterly statement data
								let financials = this.selectedEquity.financials.get(key)
								this.createXAxisCategories(financials);
								this.columns = this.createColumns(financials);
								this.rows = this.createRowsFromStatements(financials.stmtTypeId, financials.periodTypeId, financials.statementValues);
								
								this.scrollable = true;
								this.loadingData=false;

								if (this.selectedSource == INCOME_STMT && !this.incomeStmtBtnClickedFirstTime) {
									this.selectRowsBasedOnTemplateId();
									this.incomeStmtBtnClickedFirstTime = true;
								}
								this.$nextTick(() => {
									this.scrollRight();
								});
							}).catch(err => {
								console.log("error getting financial data", err);
								this.loadingData = false;
								this.$toast.add({severity:'error', summary: 'Unable to get financial data', detail:'There was an unexpected error getting the financial data.  Please contact support if the problem persists. ', life: 7000, group: 'tc'});
							
							});
					}
					else {
						let financials = this.selectedEquity.financials.get(key)
						this.createXAxisCategories(financials);
						this.columns = this.createColumns(financials);
						this.rows = this.createRowsFromStatements(financials.stmtTypeId, financials.periodTypeId, financials.statementValues);
						
						this.scrollable = true;
						this.loadingData=false;

						if ((this.selectedSource == INCOME_STMT && !this.incomeStmtBtnClickedFirstTime)) {
							this.selectRowsBasedOnTemplateId();
							this.incomeStmtBtnClickedFirstTime = true;
						}

						this.$nextTick(() => {
							this.scrollRight();
						});
					}
				}
			}catch(err){
				console.log("error getting financial data", err);
				this.loadingData = false;
				this.$toast.add({severity:'error', summary: 'Unable to get financial data', detail:'There was an unexpected error getting the financial data.  Please contact support if the problem persists. ', life: 7000, group: 'tc'});
			}
			
		},

		
		/**
		 * This makes sure that when time periods are changed the chart is updated to reflect the new values for any selected rows.
		 */
		updateSelectedLinesToGraph() {
			let newLines = [];
			
			this.selectedToGraph.forEach(oldLine => {
				if( oldLine) {
					if( oldLine.stmtType == METRICS) {
						let key = METRICS+"-"+this.selectedPeriodType.value;
						let metrics = this.selectedEquity.metrics.get(key);
						if( metrics ){
							let metricValues = [];
							metrics.metricValues.forEach(newLine => {
								if( newLine.measureId == oldLine.dataItemId) {
									metricValues.push(newLine);
								}
							});
							let newRow = this.createRowsFromMetrics(this.selectedPeriodType.value, metricValues);
							newLines.push(newRow[0]);
						}
					}
					else {
						let key = oldLine.stmtType+"-"+this.selectedPeriodType.value;
						let financials = this.selectedEquity.financials.get(key);
						if( financials ){
							let stmtValues = [];
							financials.statementValues.forEach(newLine => {

								if( newLine.dataItemId == oldLine.dataItemId) {
									stmtValues.push(newLine);
								}
								
							});
							let newRow = this.createRowsFromStatements(oldLine.stmtType, this.selectedPeriodType.value, stmtValues);
							
							newLines.push(newRow[0]);
						}
					}
				}

				
			});
			this.selectedToGraph = newLines;
			this.$refs.finChart.updateSeries(this.selectedToGraph);
			
		},
		selectRowsBasedOnTemplateId() {
			const templateId = this.equityOverview.company.statementTemplateId;

			const selectedRows = this.rows.reduce((total, row) => {
				DEFAULT_SELECTED_ROWS[templateId].forEach((name) => {
					if (row.name === name) {
						total.push(row)
					}
				})

				return total;
			}, [])

			this.selectedToGraph = selectedRows;
			this.updateSelectedLinesToGraph()
			this.$refs.finChart.hideLoading();
		},

		resetSelectedRows() {
			this.selectedToGraph = [];
			this.$refs.finChart.updateSeries(this.selectedToGraph);
		},

		onBalanceSheetClicked() {
			this.selectedSource = BALANCE_SHEET;
			this.sourceName = "Balance Sheet";
			this.expandedRows = [];
			this.fetchData();
		},
		onIncomeStatementClicked() {
			this.selectedSource = INCOME_STMT;
			this.sourceName = "Income Statement";
			this.expandedRows = [];
			this.fetchData();
		},
		onCashFlowClicked() {
			this.selectedSource = CASHFLOW_STMT;
			this.sourceName = "Cash Flow Statement";
			this.expandedRows = [];
			this.fetchData();
		},
		onMetricsClicked() {
			this.selectedSource = METRICS;
			this.sourceName = "Metrics";
			this.expandedRows = [];
			this.fetchData();
		},

    }
}
</script>
<style scoped>

.delete-metric-button{
	padding: 0.1rem;
    border-radius: 16px;
    border-color: #E63E3E;
    background-color: #e63e3e;
    margin-left: 3px;
	width: 1.5rem;
}

.item-info-button{
	padding: 0.1rem;
    border-radius: 16px;
	width: 1.5rem;

}
::v-deep(.item-info-button span.pi.pi-info.p-button-icon) {
	font-size:.8rem !important;
}
::v-deep(.delete-metric-button span.pi.pi-trash.p-button-icon) {
	font-size:.8rem !important;
}

::v-deep(.p-row-toggler){
	width:10px !important;
	height: 10px !important;
	padding:8px;
}
::v-deep(.fiscal-metric-search > input){
	border-radius:10px;
	/* border-color: #693BF5; */
	border: 1px solid #693BF5;
	padding-top: 5px;
	padding-bottom: 5px;
}

::v-deep(.p-tooltip){
	z-index:999;
}
::v-deep(.fiscal-metric-search > .p-inputtext:enabled:focus) {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #c8bcef;
    border-color: #693BF5;
}

::v-deep(.fiscal-metric-search > .p-inputtext:enabled:hover) {
    border-color: #693BF5;
}

*:not(.pi), ::v-deep(.p-component) {
	font-family: "Trebuchet MS", "Verdana";
}


.card {
	padding: 16px 16px 0px;
	margin-bottom:16px;
	width: 100%;
}
.card:last-child {
	overflow: hidden;
	padding-left: 0px;
	margin-bottom: 0px;
}
.chart-header-row {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}
.data-point-table {
	min-height: 100px;
}

/* ::v-deep(.p-datatable-scrollable .p-datatable-wrapper) {
	direction: rtl;
} */
::v-deep(.p-datatable-scrollable .p-datatable-wrapper::-webkit-scrollbar) {
	/* width */
	width: 10px;

	height: 10px;
}

::v-deep(.p-datatable-scrollable .p-datatable-wrapper::-webkit-scrollbar-track) {
	background-color: #F2F2F2;
	opacity: 1;
	transition: background-color .2s;
}

::v-deep(.p-datatable-scrollable .p-datatable-wrapper::-webkit-scrollbar-thumb) {
	background-color: #BFBFBF;    /* color of the scroll thumb */
	border-radius: 20px;       /* roundness of the scroll thumb */
	border: 9px solid #BFBFBF;  /* creates padding around scroll thumb */
}
::v-deep(.data-point-table .p-datatable-thead > tr > th) {
	background: #ffffff;
	color: #999;
	padding-left: 2.75em;
	font-weight: normal;
}
::v-deep(.data-point-table .p-datatable-tbody > tr > td:first-child),
::v-deep(.data-point-table .p-datatable-thead > tr > th:first-child) {
	padding-left: 12px;
}
::v-deep(.data-point-table .p-datatable-thead > tr > th .p-column-title) {
	font-size: 12px;
}
::v-deep(.data-point-table .p-datatable-tbody > tr > td) {
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 38.5px;
}
::v-deep(.data-point-table .p-datatable-tbody > tr.template-row) {
	color: black;
}
::v-deep(.data-point-table .p-datatable-tbody > tr.template-row > td) {
	padding-top: 5px;
	padding-bottom: 5px;
	font-weight: 550;
	font-size: 12px;
}

::v-deep(.data-point-table .p-datatable-tbody > tr> td) {
	
	font-size: 12px;
}
::v-deep(.data-point-table .p-datatable-tbody > tr:not(.p-highlight).industry-template-row:hover),
::v-deep(.data-point-table .p-datatable-tbody > tr:not(.p-highlight):hover),
::v-deep(.data-point-table .p-datatable-tbody > tr.p-highlight ){
	background: #32364E;
	color:#ffffff;
}

::v-deep(.negative) {
	color: #E63E3E;
}

.reset-button {
	border-radius: 28px;
	padding-left: 10px;
	padding-right: 10px;
	padding-top:4px;
	padding-bottom:4px;
}

.metrics-header-row {
	display: flex;
	align-items: center;
	padding-left: 16px;
	margin-bottom: 5px;
} 

.buttons-container {
	display: flex;
	margin-left: auto;
}

.statement-title {
	font-size: 26px;
	color: #32364e;
}

 ::v-deep(.custom .p-button) {
        font-family: "Trebuchet MS", "Verdana";
      
       
    }
 ::v-deep(.period-toggle .p-button.active),
    ::v-deep(.period-toggle .p-button:enabled:hover) {
        background: #32364E;
        color: #FFFFFF;
        
    }

	::v-deep(.period-toggle .p-button:focus) {
		box-shadow: none;
	}

	
	::v-deep(.period-toggle.p-selectbutton .p-button.p-highlight) {
		background: #32364E;
        color: #FFFFFF; 
		pointer-events: none;
		border-radius: 2rem;
		z-index: 2;
	}
	::v-deep(.period-toggle.p-selectbutton .p-button ) {
		border-color: #32364E;
		padding-left: 15px;
		padding-right: 15px;
		padding-top:4px;
		padding-bottom:4px;
	}
	::v-deep(.period-toggle.p-selectbutton .p-button:first-of-type ) {
		left: 20px;
	}
	::v-deep(.period-toggle.p-selectbutton .p-button:not(.p-highlight):first-of-type) {
		border-radius: 2rem 0 0 2rem;
	}
		::v-deep(.period-toggle.p-selectbutton .p-button:not(.p-highlight):last-of-type) {
		border-radius: 0 2rem 2rem 0;
	}
	::v-deep(.period-toggle.p-selectbutton .p-button:not(.p-highlight):hover) {
		background: #32364E;
        color: #FFFFFF; 
	}
	::v-deep(.period-toggle.p-selectbutton .p-button-label) {
		padding: 0.1rem .5rem;
	}
</style>
