<template>
    <Dialog v-model:visible="display" :modal="true" class='upgrade-to-premium-modal' @hide="onHide">
        <template #header>
            <span class='title'>You've discovered a <span class="header-highlight">{{subscriptionTypeName}}</span>.</span>
        </template>
        <div v-if="trialPeriodExpired" class="body-text">
            Your free trial has expired. To continue using this feature and the others below, please upgrade to Premium. 
        </div>
        <div v-else class='body-text'>
            {{bodyText}}
        </div>
        <div class='perks-container'>
            <template v-if="type === PREMIUM_SUBSCRIPTION_TYPES['PREMIUM_ANALYSIS']">
                <div class='perk-container'>
                    <i class='pi pi-check-circle' />
                    <span class='perk-title'>
                        Shareholder information
                    </span>
                </div>
                <div class='perk-container'>
                    <i class='pi pi-check-circle' />
                    <span class='perk-title'>
                        Company filings
                    </span>
                </div>
                <div class='perk-container'>
                    <i class='pi pi-check-circle' />
                    <span class='perk-title'>
                        Custom alerts
                    </span>
                </div>
                <div class='perk-container'>
                    <i class='pi pi-check-circle' />
                    <span class='perk-title'>
                        Granular trade data
                    </span>
                </div>
                <div class='perk-container'>
                    <i class='pi pi-check-circle' />
                    <span class='perk-title'>
                        Earnings calendar
                    </span>
                </div>
                <!-- <div class='perk-container'>
                    <i class='pi pi-check-circle' />
                    <span class='perk-title'>
                        Dividend calendar
                    </span>
                </div> -->
                <div class='perk-container'>
                    <i class='pi pi-check-circle' />
                    <span class='perk-title'>
                        Macroeconomic data
                    </span>
                </div>
            </template>
            <template v-else-if="type === PREMIUM_SUBSCRIPTION_TYPES['PREMIUM_PORTFOLIO']">
                <div class='perk-container'>
                    <i class='pi pi-check-circle' />
                    <span class='perk-title'>
                        Cashflow forecaster
                    </span>
                </div>
                <div class='perk-container'>
                    <i class='pi pi-check-circle' />
                    <span class='perk-title'>
                        Cashflow history (Coming soon)
                    </span>
                </div>
                <div class='perk-container'>
                    <i class='pi pi-check-circle' />
                    <span class='perk-title'>
                        What-if analysis (Coming soon)
                    </span>
                </div>
                <div class='perk-container'>
                    <i class='pi pi-check-circle' />
                    <span class='perk-title'>
                        Portfolio statistics (Coming soon)
                    </span>
                </div>
            </template>
        </div>

		<template #footer>
			<Button label="View Details" class='p-button-raised p-button-rounded view-button' @click="goToPricingPage"/>
            
		</template>
	</Dialog>
</template>

<script>
import {HOME_URL} from '../../common/config';
import {PREMIUM_SUBSCRIPTION_TYPES} from '../../common/constants';
import TimeUtils from '../../utilities/TimeUtils';

import Dialog from 'primevue/dialog';

export default {
    name: 'UpgradeToPremiumModal',
    emits: ['cancel-upgrade'],
    components: {
        Dialog
    },

    props: {
        bodyText: {
            type: String,
            required: true
        },

        type: {
            type: String
        }
    },
    data() {
		return {
            //CONSTANTS
            PREMIUM_SUBSCRIPTION_TYPES,

			display: false,
            goToBilling: false,
		}
    },

    computed: {
        trialSubscription() {
            let trialSub = null;

            if (this.type === PREMIUM_SUBSCRIPTION_TYPES['PREMIUM_PORTFOLIO']) {
                trialSub = this.$store.getters['users/subscriptionList'].filter(sub => sub.subscriptionId === 8)[0];
            } else if (this.type === PREMIUM_SUBSCRIPTION_TYPES['PREMIUM_ANALYSIS']) {
                trialSub = this.$store.getters['users/subscriptionList'].filter(sub => sub.subscriptionId === 5)[0];
            }

            return trialSub === undefined ? null : trialSub;
        },

        upgradeButtonLabel() {
            if (this.trialPeriodExpired) {
                return 'Ready to Subscribe';
            } else {
                return 'Try for Free';
            }
        },

        trialPeriodExpired() {
            return this.trialSubscription && TimeUtils.isSameOrAfterNow(this.trialSubscription.stopDate, "MMM DD, YYYY h:mm:ss A");
        },

        subscriptionTypeName() {
            let name = '';

            if (this.type === PREMIUM_SUBSCRIPTION_TYPES['PREMIUM_PORTFOLIO']) {
                name = 'Premium Portfolio Tool';
            } else if (this.type === PREMIUM_SUBSCRIPTION_TYPES['PREMIUM_ANALYSIS']) {
                name = 'Premium Analysis Tool';
            } else {
                name = 'Premium Tool';
            }

            return name;
        }
    },

    methods: {
        open() {
            this.display = true;
        },

        close() {
            this.display = false;
        },

        onHide() {
            if (this.goToBilling || this.initiatedFreeTrial) {
                return;
            } else {
                this.$emit('cancel-upgrade');
            }
        },

        openFullComparison() {
            window.open(HOME_URL + '/plans?loggedIn=true', '_blank');
            this.close();
        },

        goToPricingPage() {
            // go to pricing page before checkout
            this.goToBilling = true;
            this.close();
            this.$router.push('/billing');
        },
    }
}
</script>

<style>
.upgrade-to-premium-modal {
    font-family: "Trebuchet MS", "Verdana";
    width: 550px;
}

.upgrade-to-premium-modal .p-dialog-header {
    padding: 0px 8px 0px 0px;
}

.upgrade-to-premium-modal .p-dialog-content {
    padding: 0px 18px 0px;
}

.upgrade-to-premium-modal .p-dialog-footer {
    padding: 34px 0px;
    text-align: center;
}
</style>
<style scoped>
 *:not(.pi), ::v-deep(.p-component) {
    font-family: 'Trebuchet MS', 'Verdana';
} 

.p-dialog-header .title {
    font-weight: bold;
    color: #32364e;
    font-size: 24px;
    padding: 16px 0px 16px 16px;
    text-align: center;
}

.header-highlight {
    color: #693BF5;
}

.body-text {
    text-align: center;
    font-size: 18px;
    color: #32364e;
    margin: 12px 0px 30px;
}

.perks-container {
    text-align: center;
}

.perk-container {
    margin-bottom: 16px;
}
.perk-container i {
    color: #33CC99;
}
.perk-container span {
    color: black;
    font-size: 18px;
}
.perk-container:last-child {
    margin-bottom: 0px;
}

.upgrade-button, .view-button {
    font-size: 18px;
    font-weight: bold;
    padding: 8px 64px;
    margin: 0px;
}

.view-button {
    background-color: #fff;
    color: #32364e;
    border: 1px solid #32364e;
    font-weight: normal;
}

.view-button:hover {
    background-color: #32364e;
    border: 1px solid #32364e;
}

.p-button:last-child {
    margin-right: 0 !important;
}
</style>