<template>
    <div :class="{'attribution-footer': true, 'row-layout': authorList.length > 1}">
        <template v-for='(author, i) in authorList' :key='author'>
            <template v-if="author === QUOTE_MEDIA">
                <a href="https://www.quotemedia.com/coverage" class="qmf-link">Financial Market Data</a> copyright &copy; 2021 
                <a href="https://www.quotemedia.com" class="qmf-link">QuoteMedia</a>. 
                <a href="https://www.quotemedia.com/coverage" class="qmf-link">Market Data</a> powered by 
                <a href="https://www.quotemedia.com" class="qmf-link">QuoteMedia</a>. 
                <a href="https://www.quotemedia.com/legal/tos/" class="qmf-link">Terms of Use</a>.
            </template>
            <template v-else-if="author === XIGNITE">
                <a ref="http://www.xignite.com" title="Market Data by Xignite" target="_blank">Market Data</a> by Xignite
            </template>

            <span v-if='i !== authorList.length - 1'>| </span>
        </template>
    </div>
</template>

<script>

const QUOTE_MEDIA = 'quoteMedia';
const XIGNITE = 'XIgnite';
const USED_AUTHOR_LIST = [QUOTE_MEDIA, XIGNITE];

export default {
    name: 'AttributionFooter',
    props: {
        authors: {
            type: [String, Array],
            required: true,
            validator(value) {
                if (typeof(value) === 'string') {
                    return USED_AUTHOR_LIST.includes(value);
                } else if (typeof(value) === 'object') {
                    
                    if (Array.isArray(value)) {
                        return USED_AUTHOR_LIST.every((author) => {
                            return value.includes(author);
                        });
                    }

                    return false;
                }

                return false;
            }
        }
    },

    data() {
        return {
            // CONSTANTS
            QUOTE_MEDIA,
            XIGNITE,

            authorList: []
        }
    },

    created() {
        if (typeof(authors) === 'string') {
            this.authorList.push(this.authors);
        } else {
            const list = this.authorList.concat(this.authors);
            this.authorList = list;
        }
    }
}
</script>

<style scoped>
.attribution-footer {
    font-family: 'Trebuchet MS', 'Verdana';
    text-align: center;
    background: #F2F4FA;
    color: #a6a6a6;
    margin-top: 64px;
}
.attribution-footer .row-layout {
    display: flex;
}

.attribution-footer a {
    color: #a6a6a6;
}
.attribution-footer .qmf-link { 
	white-space: nowrap; 
} 
</style>