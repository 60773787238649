<template>
    <highcharts :options="chartOptions" :constructor-type="'stockChart'" ref="chartComponent" class="chart financialsChart" style="margin: 0px 20px 0px 0px; border-radius: 16px; top: -10px;"></highcharts>
    <SnapshotDialog v-model:displaySnapShot="displaySnapshot" :target="snapshotTarget" :cropWidthAmount="60"/>
    
   
    <!-- <StatusEditorDialog  ref="statusEditor" @hide="onStatusEditorHidden"/> -->
    
</template>
<script>

import numeral from 'numeral';
// import StatusEditorDialog from '../../feed/StatusEditorDialog.vue';
import SnapshotDialog from '../../modal/SnapshotDialog.vue';

export default {
    name: 'FinancialsChart',
    props: {
        
        selectedSymbol: {
            type: String,
            required: true,
           
        },
       
    },
    components: {
        
        // StatusEditorDialog,
        SnapshotDialog,
    },
    

    data() {
        return {
        //    showStatusEditor: false,
           
            dataSets:[],
            categories:[],
            snapshotTarget: null,
            
            displaySnapshot: false,
            currentRange: "Y",
            currentSymbol: "",
            chartSeries: [],
            chartOptions : { 
                chart: {
                    height: 350
                },
                xAxis:{ type: 'category',  categories: []},
                legend: {
                    enabled: true,
                    verticalAlign: 'top'
                },
                tooltip: {
                    shared: true,
                    split: false,
                    headerFormat: '<table>',
                    pointFormat: '<tr><td style="color:{series.color}; padding: 0">{series.name}: </td>' +
                            '<td style="padding:0"><b>{point.y}</b></td></tr>',
                   footerFormat: '</table>',
                   useHTML: true
                },
                navigator: {
                   
                    enabled: false
                },
                scrollbar: {
                    enabled: false
                },
                rangeSelector: {
                    enabled:false,
                },
                stockTools: {
                    gui: {
                        buttons: [  'simpleShapes',  'separator',  'lines',  'separator',  'toggleAnnotations', 'separator', 'fullScreen']
                    }

                },
                plotOptions: {
                    series: {
                        borderWidth: 0,
                        opacity: 0.85
                    }
                },
                //series: [],
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 800
                        },
                        chartOptions: {
                            rangeSelector: {
                                inputEnabled: false
                            }
                        }
                    }]
                },
                time: {
                   useUTC: false
                }
            },
               
            
        }
        

        
    },
   
    activated() {
       console.log("financials chart activated")
        if( this.currentSymbol != this.$props.selectedSymbol) {
            this.currentSymbol = this.$props.selectedSymbol;
            this.$refs.chartComponent.chart.setTitle(this.currentSymbol);
           
            this.clearChart();
           
        }
        this.$refs.chartComponent.chart.reflow();

    },
     deactivated() {
        this.displaySnapshot = false;
    },
    
    methods : {
        showLoading( msg ) {
            this.$refs.chartComponent.chart.showLoading(msg);
        },

        hideLoading() {
             this.$refs.chartComponent.chart.hideLoading();
        },

        // onStatusEditorHidden() {
        //     this.$nextTick().then(() => {
        //          this.$refs.chartComponent.chart.redraw();
        //     });
           

        // },
        toggleMenu(event) {
            this.$refs.menu.toggle(event);
        },
        clearChart() {
            console.log("clear chart called");
            
            while (this.$refs.chartComponent.chart.series.length > 0) {
                this.$refs.chartComponent.chart.series[0].remove(true);
            }
            this.$refs.chartComponent.chart.annotations.forEach(annotation => annotation.destroy())
            this.$refs.chartComponent.chart.annotations.length = 0;
           this.$refs.chartComponent.chart.yAxis.length = 0;
            
        },
        updateSeries(toGraph) {
            this.dataSets = toGraph;
            this.chartSeries = [];
           //console.log("updating series to "+ JSON.stringify(this.dataSets));
            while (this.$refs.chartComponent.chart.series.length) {
                this.$refs.chartComponent.chart.series[0].remove(true);
            }
            var i = 0;
            let colors = ['#33CC99', '#E63E3E',"#693BF5","#FFBD53","#5367FF"];
            this.dataSets.forEach(line => {
                if( line ) {
                    if( this.isDollarBased(line)) {
                        
                        let series = {
                                type: 'column',
                                id: ""+line.dataItemId,
                                name: line.name,
                                // dataLabels: {
                                //     formatter:function() {return numeral(this.y).format('0.00a')},
                                // },
                                color:  colors[i],
                                data: line.series,
                                yAxis: 'dollar-based-axis',
                        };
                        this.chartSeries.push(series);
                        this.$refs.chartComponent.chart.addSeries(series );
                        
                    }
                    else {
                        
                        let series = { };



                        if (line.valueType == 'Ratio') {
                            series = {
                                type: 'line',
                                id: ""+line.dataItemId,
                                name: line.name,
                                // dataLabels: {
                                //     formatter:function() {return numeral(this.y).format('0.0000')},
                                // },
                                color:  colors[i],
                                data: line.series,
                                yAxis: 'ratio-based-axis'
                            };
                        } else if (line.valueType == 'Percent' || line.valueType == 'Decimal Percentage') {
                            series = {
                                type: 'line',
                                id: ""+line.dataItemId,
                                name: line.name,
                                // dataLabels: {
                                //     formatter:function() {return numeral(this.y).format('0.0000')},
                                // },
                                color:  colors[i],
                                data: line.series,
                                yAxis: 'percent-based-axis'
                            };
                        
                        } else if (line.valueType == 'Actual') {
                            series = {
                                type: 'line',
                                id: ""+line.dataItemId,
                                name: line.name,
                                // dataLabels: {
                                //     formatter:function() {return numeral(this.y).format('0.0000')},
                                // },
                                color:  colors[i],
                                data: line.series,
                                yAxis: 'actual-based-axis'
                            };
                        }

                        //console.log("adding series "+ JSON.stringify(series));

                        this.chartSeries.push(series);
                        this.$refs.chartComponent.chart.addSeries(series);
                    }
                    ++i;
                }
                
            });
            
            this.$refs.chartComponent.chart.redraw();
            this.$refs.chartComponent.chart.reflow();

            // this.$refs.chartComponent.chart.series.forEach(series => {
                    
            // });
            
        },

        isDollarBased(line) {
            return ( line.valueType == 'Millions' || line.valueType =='$ & cents');
        },
        updateCategories( cats) {
            this.categories = cats;
            //console.log("updating categories to "+ JSON.stringify(this.categories));
            this.$refs.chartComponent.chart.xAxis[0].update({categories: this.categories}, true);
            if( this.$refs.chartComponent.chart.yAxis.length <= 0 ) {
                this.$refs.chartComponent.chart.addAxis({
                        labels: {
                             formatter: function () {
                                 return '$'+numeral(this.value).format('0a');
                             }
                        },
                        id: 'dollar-based-axis',
                        lineWidth: 1,
                        opposite:false,
                        gridLineDashStyle: 'dot',
                       
                    });
                 this.$refs.chartComponent.chart.addAxis( {
                        labels: {
                            align: 'left',
                            format: '{value:.2f}'
            
                        },
                        lineWidth: 0,
                        id: 'percent-based-axis',
                       
                        gridLineDashStyle: 'dot',
                    });
                    // this.$refs.chartComponent.chart.addAxis( {
                    //     labels: {
                    //         align: 'left',
                    //         format: '{value:.2f}'
            
                    //     },
                    //     lineWidth: 0,
                    //     id: 'ratio-based-axis',
                       
                    //     gridLineDashStyle: 'dot',
                    // });

                    this.$refs.chartComponent.chart.addAxis({
                        labels: {
                            align: 'left',
                            formatter: function () {
                                return numeral(this.value).format('0.00') + 'x';
                            } 
                        },
                        lineWidth: 0,
                        id: 'ratio-based-axis',

                        gridLineDashStyle: 'dot',
                    });

                    this.$refs.chartComponent.chart.addAxis({
                        labels: {
                            align: 'left',
                            format: '{value:.2f}',
                        },
                        lineWidth: 0,
                        id: 'actual-based-axis',

                        gridLineDashStyle: 'dot',
                    });
            }
            
        },

       

        openSnapshotDialog() {
            
            this.snapshotTarget = this.$refs.chartComponent.$el.getElementsByClassName('highcharts-container')[0];
            this.displaySnapshot = true;
            
           
        },

        
        
    
    }
  }
</script>

<style scoped>

.financialsChart {
    max-height: 400px;
	min-height: 35vh;
    float: none !important;
    height: auto;
}

::v-deep(.highcharts-menu-wrapper) {
    display:flex;
    flex-direction: column;
    justify-content: center;
}

::v-deep(.highcharts-toggle-toolbar) {
    display: none;
}

.snapshot {
    /* TBD */
    /* border: none;
    /* 2px solid rgb(172,172,173) !important; */
    /* color: rgb(122,122,123) !important; */
    /* #33CC99 !important; */
    /* color: #495057; */
    /* background-color: #ffffff; */
} 

/* 
.snapshot:active {
    border: 2px solid #33CC99 !important;
}
.snapshot:focus {
    box-shadow: none;
    border: 2px solid #33CC99 !important;
}
*/
.snapshot:hover {
    /* TBD */
    /* background-color: #33cc99 !important;
    border: 2px solid #33cc99 !important;
    color:#e9ecef !important; */

} 

/* Begin GUI tools */

/* ::v-deep(.highcharts-annotation-shapes > circle){
    fill: rgba(166, 166, 166, 0.3);
    stroke: rgba(166, 166, 166, 0.3);
} */

/* ::v-deep(.highcharts-annotation-shapes > path){
     fill: rgba(166, 166, 166, 0.3);
    stroke: rgba(166, 166, 166, 0.3);
    stroke-width: 3;
} */


/* end GUI tools */

/* the range selector buttons*/

::v-deep(.invrs-chart-button-pressed > rect) {
    background-color: #33CC99;
    fill: #33CC99;
  
}

::v-deep(.invrs-chart-button-pressed >  text) {

      color:#ffffff !important;
      fill: #ffffff !important;
    font-weight: normal !important;
}

::v-deep([class*='highcharts']) {
    font-family: "Trebuchet MS", "Verdana";
}

::v-deep(.highcharts-range-label) {
    color: #33CC99;
}


/** hide the highcharts logo */
::v-deep(.highcharts-credits) {
	
    display:none;
}


/** highcharts popup styling */
::v-deep(.highcharts-popup button) {
   
    padding: 0.75rem 1rem;
    
}

:v-deep(.highcharts-popup button:hover) {
    background: #e9ecef;
}

::v-deep(.highcharts-popup-bottom-row) {
    /* //float: left;
    /* padding: 0px 20px; */
    /* width: calc(100% - 40px);  */
    padding: 0.5rem;
}

::v-deep(.highcharts-bindings-wrapper .highcharts-stocktools-toolbar li ){
    border-radius:4px !important;
}
::v-deep(.highcharts-menu-item-btn) {
    border-radius:4px !important;
}
::v-deep(.highcharts-axis-labels.highcharts-yaxis-labels text) {
    color: #32364e !important;
    fill: #32364e !important;
    font-size: 12px !important;
}
::v-deep(.highcharts-axis-labels.highcharts-xaxis-labels text) {
    color: #999 !important;
    fill: #999 !important;
    font-size: 14px !important;
}
::v-deep(.highcharts-axis.highcharts-yaxis .highcharts-axis-line) {
    stroke: none;
}

::v-deep(.highcharts-legend .highcharts-legend-item text) {
    color: #999 !important;
    fill: #999 !important;
    font-size: 14px !important;
    font-weight: normal !important;
}
::v-deep(.highcharts-legend .highcharts-legend-item:hover text) {
    color: #32364e !important;
    fill: #32364e !important;
}

</style>